import { CarouselContainer } from "@components/container/carousel";
import { SwiperSlide } from "swiper/react";
import { TAsset, TAssetGroup, TAssetService, TBrand, TProduct, TProductCategory } from "./../types";
import { SimpleCard } from "./product/simple";
export { StoreCartContainer } from "../container/storecart";
export { AddressSmallCard } from "./address/addresssmall";
export { BrandCard } from "./brands";
export { ExporeBrandCard } from "./brands/explore";
export { CategoryCard } from "./category";
export { SmallCategoryCard } from "./category/xsmall";
export { FeatureCard } from "./feature";
export { InventoryProductCard } from "./inventory";
export { MainCard } from "./main";
export { OrderInfoCard } from "./order/info";
export { OrderSummaryCard } from "./order/summary";
export { OrderItemCard } from "./orderitem";
export { PaymentCard } from "./payment";
export { ProductCategoryCard } from "./product/category";
export { RichCard, RichCardV2 } from "./product/rich";
export { SimpleCard } from "./product/simple";
export { SmallProductCard } from "./product/small";
export { ProductCardWithProvider } from "./productcardwithprovider";
export { ReviewCard } from "./review";
export { StoreReviewCard } from "./review/store";
export { StoreTopCard } from "./store";
export { SmallStoreCard } from "./store/small";

interface Props {
    products: (TProduct | TProductCategory | TBrand | TAsset | TAssetGroup | TAssetService)[]
    intent?: string
    cardWidth?: string
    element?: React.ElementType
    isCarousel?: boolean
    isAvailable?: boolean
    loading?: "lazy" | "eager"
}

const INTENT: Map<string, number> = new Map([["product", 0], ["category", 1], ["asset", 2]]);
export const Cards = ({ cardWidth, element, loading, products, intent, isCarousel, isAvailable }: Props) => {
    let RepeatElement = element == undefined || element == null ? SimpleCard : element;
    let cards;
    switch (INTENT.has(intent) ? INTENT.get(intent) : 0) {
        case 1:
            cards = products && (products as TProductCategory[])?.map((element: TProductCategory) => (
                <RepeatElement product={element} key={element.id} loading={loading} isAvailable={true}></RepeatElement>
            ))
        case 2:
            cards = products && (products as TAsset[])?.map((element: TAsset) => (
                <RepeatElement product={element} key={element.id} loading={loading} isAvailable={true}></RepeatElement>
            ))
        default:
            cards = products && (products as TProduct[])?.map((element: TProduct) =>
                {
                    let available = element.inventories?.map((x) => x.is_available).some((x) => x);
                    return <RepeatElement product={element} key={element.id} loading={loading} isAvailable={available}></RepeatElement>
                }
            )
    }
    return (
        isCarousel ?
        <div>
            <CarouselContainer >
                {
                    cards.map((item, index) => {
                        return <SwiperSlide style={{ width: "auto" }} className={`min-w-0 ${cardWidth}`} key={index}>{item}</SwiperSlide>
                    })
                }
            </CarouselContainer>
        </div>
        :
        <div className="flex gap-4 rounded-xl flex-row overflow-x-scroll no-scrollbar">
            {
                cards
            }
        </div>
    )
}