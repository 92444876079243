import { AddToCart } from '@components/addtocart';
import { CartButton as CartButtonV4 } from '@components/cartbutton/v4';
import { Star } from '@components/star';
import { TInventory } from '@components/types';
import { getCombinedName } from '@components/variant/variantSelector';
import {
    getImageObject,
    getSafeUrl,
    humanizeCurrency,
    sanityIoImageLoader,
    trimToLength
} from '@core/utils';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
  element: TInventory;
  isAvailable?: boolean;
};

export const InventoryProductCard = ({ element, isAvailable }: Props) => {
  return (
    <div className={`flex flex-row gap-3 filter ${isAvailable ? "grayscale-0" : "grayscale"} bg-neutral-50 rounded-md border border-solid border-neutral-300 overflow-hidden`}>
      <div className="flex items-start justify-start">
        <div className="flex justify-center max-h-36 h-full">
          <Link
            href={`/product/${element?.product?.id}/${getSafeUrl(
              element?.product?.name
            )}?store_id=${element?.store_id}`}
          >
            <Image
              loader={sanityIoImageLoader}
              src={getImageObject(element?.product?.images)?.url}
              alt={
                getImageObject(element?.product?.images, element?.product?.category?.image)?.description ||
                element?.product?.name ||
                'Product'
              }
              width="128"
              height="128"
              className={`aspect-square h-full`}
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-1 py-3 items-start justify-between pr-2">
        <div className="flex flex-col gap-1 leading-tight font-manrope overflow-hidden">
          <Link
            href={`/product/${element?.product?.id}/${getSafeUrl(
              element?.product?.name
            )}?store_id=${element?.store_id}`}
          >
            <div className="font-semibold md:text-base text-sm text-neutral-900 font-manrope line-clamp-1">
              {trimToLength(
                getCombinedName(element?.product, null, element?.variant),
                40
              )}
            </div>
          </Link>
        </div>
        <div className="flex flex-col gap-2 w-full">
          {element?.product?.brand_id && (
            <div className="text-xs text-neutral-500 font-manrope">
              <span className="font-semibold">Brand: </span>
              {element?.product?.brand?.name || 'Brand'}
            </div>
          )}

          <div className="flex flex-row gap-2 items-center">
            {element?.product?.stats?.rating_overall ? (
              <Star rating={element?.product?.stats?.rating_overall} />
            ) : (
              <div className="flex flex-row gap-1 justify-between items-center text-neutral-500 text-sm">
                No Reviews
              </div>
            )}
          </div>

          <div className="flex gap-2">
            {element?.product?.is_pickup && (
              <span className="h-8 px-4 flex items-center text-xs text-neutral-800 font-bold tracking-wider uppercase rounded bg-red-100">
                Pickup
              </span>
            )}
            {element?.product?.is_delivery && (
              <span className="h-8 px-4 flex items-center text-xs text-neutral-800 font-bold tracking-wider uppercase rounded bg-blue-100">
                Delivery
              </span>
            )}
          </div>
          <div className="flex gap-10 items-center">
            <div>
              {element?.is_available == true ? (
                <div className="flex flex-col items-left">
                  <span className="text-sm md:text-base text-neutral-800 font-manrope font-semibold">
                    {humanizeCurrency(element?.price)}
                  </span>
                  {element?.price > element?.mrp && (
                    <span className="text-xs line-through text-neutral-500 font-manrope">
                      {humanizeCurrency(element?.mrp)}
                    </span>
                  )}
                </div>
              ) : (
                <span className="text-sm text-error-400 font-semibold">
                  Unavailable
                </span>
              )}
            </div>
            {element?.is_available ? (
              <div className="flex flex-1 flex-row">
                <AddToCart
                  product={element?.product}
                  inventory={element}
                  addToCartBag={false}
                  BtnType={CartButtonV4}
                  isAvailable={isAvailable}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
